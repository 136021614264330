@use '../_config.scss' as *;
@use '../components/section';
@use "../../../node_modules/swiper/modules/pagination.scss";
@use "../../../node_modules/swiper/modules/navigation.scss";
@use "../../../node_modules/swiper/modules/grid.scss";

.banner {
    @include imageRatio(1920, 650, false, contain);

    @media (max-width: 425px) {
        @include imageRatio(840, 500, false, contain);
    }

    &__swiper-img {
        width: 100%;
    }

    &__prev-btn {
        background-color: rgba(0, 0, 0, 0.2);
        border-radius: 0px 8px 8px 0px;
        padding: 15px 20px;
    }

    &__next-btn {
        background-color: rgba(0, 0, 0, 0.2);
        border-radius: 8px 0px 0px 8px;
        padding: 15px 20px;
    }

    &__icon-btn {
        font-size: 20px;
        color: color(black, white);
    }

    &__pagination {
        .swiper-pagination-bullet {
            width: 30px;
            height: 3px;
            border-radius: 0px;
        }

        .swiper-pagination-bullet-active {
            background-color: color(black, white);
        }
    }

    .swiper-button-prev {
        left: 0px;
    }

    .swiper-button-next {
        right: 0px;
    }

    .swiper-button-prev:after,
    .swiper-button-next:after {
        content: '';
    }
}

.categories {
    @include imageRatio(180, 180, false, contain);

    &__swiper-img {
        text-align: center;
        border-radius: 8px;
        padding: 12px;
        max-width: 161px;
    }

    &__swiper-text {
        @include fontSize(body, body-1, false);
        text-align: center;
        padding-top: 12px;
        margin-bottom: 13px;
    }

    &__swiper-container {
        position: relative;
    }

    &__prev-btn {
        left: -42px;

        @media (max-width: 1060px) {
            left: 38%;
        }
    }

    &__next-btn {
        right: -42px;
        @media (max-width: 1060px) {
            right: 38%;
        }
    }

    &__nav-btn {
        position: absolute;
        background-color: color(black, white);
        padding: 5px 12px 7px 12px;
        color: color(black);
        border-radius: 8px;
        bottom: 46%;
        transform: translateY(-50%);
        display: block;
        @include transition(all 0.5s ease);
        
        &:hover {
            background-color: color(black, light-grey);
        }

        @media (max-width: 991.98px) {
            display: none;
        }

        @media (max-width: 1060px) {
            bottom: -42px;
        }
    }

    &__img-link {
        display: block;
    }

    .swiper-button-prev:after,
    .swiper-button-next:after {
        content: '';
    }

    .swiper-button-disabled {
        opacity: 0.35;
    }

    @media (max-width: 575.98px) {
        &__swiper-img {
            max-width: 120px;
        }
    }

    @media (max-width: 375px) {
        @include imageRatio(103, 103, false, contain);
    }
}

.brands {
    @include imageRatio(160, 50, false, contain);

    &__swiper-container {
        position: relative;
    }

    &__swiper {
        padding-top: 30px;
        padding-bottom: 30px;
    }

    &__nav-btn {
        position: absolute;
        background-color: color(black, white);
        padding: 5px 12px 7px 12px;
        color: color(black);
        border-radius: 8px;
        bottom: 50%;
        display: block;
        transform: translateY(50%);
        @include transition(all 0.5s ease);

        &:hover {
            background-color: color(black, light-grey);
        }

        @media (max-width: 991.98px) {
            display: none;
        }

        @media (max-width: 1060px) {
            bottom: -20px;
            margin-bottom: 25px;
        }
    }

    &__prev-btn {
        left: -42px;

        @media (max-width: 1060px) {
            left: 38%;
        }
    }

    &__next-btn {
        right: -42px;

        @media (max-width: 1060px) {
            right: 38%;
        }
    }

    &__swiper-img {
        border-radius: 8px;
        background: color(black, white);
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.10);
        padding: 8px 10px;
        max-width: 161px;
    }

    .swiper-button-disabled {
        opacity: 0.35;
    }

    @media (max-width: 575.98px) {
        &__swiper-img {
            max-width: 120px;
        }
    }
}

.special-price {
    background-color: color(red);
    background-image: url("../../../assets/images/discount-light.png");
    background-repeat: no-repeat;
    background-position: left bottom;
    padding-top: 60px;
    padding-bottom: 60px;

    &__swiper-container {
        position: relative;
    }

    &__nav-btn {
        position: absolute;
        background-color: rgba(0, 0, 0, 0.2);
        padding: 5px 12px 7px 12px;
        color: color(black);
        border-radius: 8px;
        bottom: 46%;
        display: block;

        @media (max-width: 991.98px) {
            display: none;
        }

        @media (max-width: 1060px) {
            bottom: -55px;
        }
    }

    &__prev-btn {
        left: -42px;
        @media (max-width: 1060px) {
            left: 38%;
        }

    }

    &__next-btn {
        right: -42px;
        @media (max-width: 1060px) {
            right: 38%;
        }
    }

    &__title {
        padding-left: 8px;

        &:after {
            content: "SPECIAL PRICE THIS MONTH";
        }

        @media (max-width: 991px) {
            &:after {
                content: "SPECIAL PRICE";
            }
        }
    }

    &__category-item{
        height: unset !important;
    }

    .swiper-button-disabled {
        opacity: 0.35;
    }

    @media (max-width: 1024px) {
        padding-bottom: 100px;
    }

    @media (max-width: 991px) {
        padding-top: 27.5px;
        padding-bottom: 27.5px;
    }
}

.main-banner {
    @include imageRatio(716, 403, true, contain);
    padding-top: 30px;

    &__img {
        width: 100%;
        border-radius: 8px;
    }

    @media (max-width: 991.98px) {
        margin-top: 0px;
        padding-top: 0;
        @include imageRatio(543, 306, true, contain);
    }
}

.featured-products {
    padding-top: 101px;
    padding-bottom: 30px;
    
    @media screen and (max-width:991.98px) {
        padding-top: 24px;
        padding-bottom: 24px;
    }
}

.products {
    &__col {
        padding-left: 15px;
        padding-right: 15px;
    }
}

@media screen and (max-width: 991.98px) {
    .footer{
        margin-top: 100px;
    }
}